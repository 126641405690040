import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import checkBoxSelectedIcon from "assets/icons/ui/checkbox-checked.svg";
import checkBoxEmpyIcon from "assets/icons/ui/checkbox-unchecked.svg";
import React from "react";

export const breakpoints = createBreakpoints({
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const typographyClasses = {
  display: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "61px",
    lineHeight: "72px",
    fontWeight: 300,
  },
  h1: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "39px",
    lineHeight: "48px",
    [breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "32px",
    },
  },
  h2: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "32px",
    lineHeight: "40px",
    [breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "32px",
    },
  },
  h3: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "25px",
    lineHeight: "32px",
    fontWeight: 500,
    [breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  h4: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "21px",
    lineHeight: "28px",
    fontWeight: 700,
    [breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  h5: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    [breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  body1: {
    fontSize: "16px",
    lineHeight: "24px",
    [breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  body2: {
    fontSize: "14px",
    lineHeight: "20px",
    [breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  subtitle1: {
    fontSize: "12px",
    lineHeight: "16px",
    [breakpoints.down("sm")]: {
      fontSize: "11px",
      lineHeight: "14px",
    },
  },
  subtitle2: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 700,
    [breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
    [breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  button: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  link: {
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  message: {
    fontSize: "11px",
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    marginRight: "4px",
    marginLeft: "4px",
  },
};

const inputClasses = {
  text: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "14px",
    lineHeight: "20px",
    caretColor: "#EF350C",
    color: "#000000 !important",
    borderRadius: "16px",
    border: "1px solid #000000 !important",
    borderColor: "#000000 !important",
    "&:focus": {
      borderColor: "#000000 !important",
      border: "2px solid #000000 !important",
    },
    "&:focus-visible": {
      border: "2px solid #000000 !important",
      borderColor: "#000000 !important",
    },
    "&:focus-within": {
      border: "2px solid #000000 !important",
      borderColor: "#000000 !important",
    },
    "&:hover": {
      border: "2px solid #000000 !important",
      borderColor: "#000000 !important",
    },
    "&:active": {
      border: "2px solid #000000 !important",
      borderColor: "#000000 !important",
    },
    "&:visited": {
      border: "2px solid #000000 !important",
      borderColor: "#000000 !important",
    },
  },
  title: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  helper: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#757575",
  },
  error: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#bd2900",
  },
  checkBox: {
    color: "#000000",
    "&.Mui-checked": {
      color: "#000000",
    },
  },
  radioButton: {
    color: "#000000",
    "&.Mui-checked": {
      color: "#000000",
    },
  },
  textFieldLabel: {
    root: {
      fontFamily: "TCCC-UnityHeadline",
      color: "#807F80 !important",
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: 600,
        color: "#404040 !important",
      },
    },
    focus: {
      fontWeight: 600,
      color: "#000000 !important",
    },
    error: {
      fontWeight: 600,
      color: "#000000 !important",
    },
  },
  textFieldInput: {
    root: {
      fontFamily: "TCCC-UnityHeadline",
      fontSize: "14px",
      lineHeight: "20px",
      caretColor: "#EF350C",
      color: "#000000 !important",
      borderRadius: "16px",
      border: "0px",
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #EF350C !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #000000",
      },
    },
    focus: {},
    error: {},
    notchedOutline: {
      fontFamily: "TCCC-UnityHeadline",
      borderRadius: "16px",
      border: "1px solid #000000",
    },
  },
  disabledTextFieldLabel: {
    root: {
      fontFamily: "TCCC-UnityHeadline",
      color: "#807F80 !important",
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: 500,
        color: "##807F80 !important",
      },
    },
    focus: {
      fontWeight: 500,
      color: "#807F80 !important",
    },
    error: {
      fontWeight: 500,
      color: "#807F80 !important",
    },
  },
  disabledTextFieldInput: {
    root: {
      fontFamily: "TCCC-UnityHeadline",
      fontSize: "14px",
      lineHeight: "20px",
      caretColor: "#EF350C",
      color: "#807F80 !important",
      borderRadius: "16px",
      border: "0px",
      background: "#DFDFDF",
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #807F80 !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #807F80",
      },
    },
    focus: {},
    error: {},
    notchedOutline: {
      fontFamily: "TCCC-UnityHeadline",
      borderRadius: "16px",
      border: "1px solid #807F80",
    },
  },
  textFieldHelperText: {
    root: {},
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: "#D80000 !important",
        backgroundColor: "#F3DDDD",
        width: "fit-content",
        borderRadius: "8px",
        padding: "2px 6px",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 700,
        marginLeft: "0px",
      },
    },
  },
  mobileStylePrimary: {
    "&:hover": {
      backgroundColor: "#e02020",
    },
  },
  mobileStyleSecondary: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  infoMessage: {
    background: "#EAF3F6",
    color: "#404040",
    borderRadius: "6px",
    margin: "auto",
    padding: "2px",
    borderWidth: "4px 0px 0px 0px",
    borderStyle: "solid",
    borderTopColor: "#00A1FF",
  },
  successMessage: {
    background: "#ECF5EF",
    color: "#404040",
    borderRadius: "6px",
    margin: "auto",
    padding: "2px",
    borderWidth: "4px 0px 0px 0px",
    borderStyle: "solid",
    borderTopColor: "#8FC34E",
  },
  errorMessage: {
    background: "#F3DDDD",
    color: "#404040",
    borderRadius: "6px",
    margin: "auto",
    padding: "2px",
    borderWidth: "4px 0px 0px 0px",
    borderStyle: "solid",
    borderTopColor: "#D80000",
  },
  warningMessage: {
    background: "#F7F2E8",
    color: "#404040",
    borderRadius: "6px",
    margin: "auto",
    padding: "2px",
    borderWidth: "4px 0px 0px 0px",
    borderStyle: "solid",
    borderTopColor: "#FFE11A",
  },
  defaultMessage: {
    background: "#F5F5F5",
    color: "#404040",
    borderRadius: "6px",
    margin: "auto",
    padding: "2px",
  },
};

const ecommerceTheme = createTheme({
  props: {
    MuiCheckbox: {
      checkedIcon: <img src={checkBoxSelectedIcon} alt="Checked" />,
      icon: <img src={checkBoxEmpyIcon} alt="Unchecked" />,
    },
  },
  breakpoints: breakpoints,
  typography: {
    fontFamily: "TCCC-UnityHeadline",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: typographyClasses.h1,
    h2: typographyClasses.h2,
    h3: typographyClasses.h3,
    h4: typographyClasses.h4,
    h5: typographyClasses.h5,
    body1: typographyClasses.body1,
    body2: typographyClasses.body2,
    subtitle1: typographyClasses.subtitle1,
    subtitle2: typographyClasses.subtitle2,
    button: typographyClasses.button,
    caption: typographyClasses.caption,
    h6: typographyClasses.message,
  },
  overrides: {
    MuiTypography: {
      paragraph: {
        marginBottom: "48px",
        [breakpoints.down("sm")]: {
          marginBottom: "32px",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        backgroundColor: "transparent",
        borderRadius: "10px",
        color: "#000",
        [breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        "&:hover.Mui-disabled": {
          backgroundColor: "#D4D4D4 !important",
        }
      },
      textPrimary: {
        backgroundColor: "#000",
        width: "100%",
        border: "2px solid #000",
        "&:hover": {
          backgroundColor: "#e02020",
          border: "2px solid #e02020",
        },
        "&:focus": {
          outline: "double #000",
        },
        "&:disabled": {
          backgroundColor: "#D4D4D4",
          color: "#fff",
          border: "2px solid #D4D4D4",
        },
        color: "#fff",
      },
      textSecondary: {
        backgroundColor: "#fff",
        width: "100%",
        border: "2px solid #000",
        "&:hover": {
          border: "2px solid #e02020",
          color: "#e02020",
          backgroundColor: "#fff",
        },
        "&:focus": {
          outline: "double #000",
        },
        "&:disabled": {
          backgroundColor: "#fff",
          color: "#D4D4D4",
          border: "2px solid #D4D4D4",
        },
        color: "#000",
      },
      containedPrimary: {
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#e02020",
        },
        "&:focus": {
          outline: "double #000",
        },
        "&:disabled": {
          backgroundColor: "#D4D4D4",
          color: "#fff",
        },
        color: "#fff",
      },
      containedSecondary: {
        backgroundColor: "#fff",
        border: "2px solid #000",
        "&:hover": {
          border: "2px solid #e02020",
          color: "#e02020",
          backgroundColor: "#fff",
        },
        "&:focus": {
          outline: "double #000",
        },
        "&:disabled": {
          backgroundColor: "#fff",
          color: "#D4D4D4",
          border: "2px solid #D4D4D4",
        },
        color: "#000",
      },
    },
    MuiCheckbox: {
      checked: {
        color: "#000",
      },
      disabled: {
        color: "#fff",
      },
      colorPrimary: {
        color: "#000",
      },
      colorSecondary: {
        color: "#000",
      },
    },
  },
});

export { typographyClasses, inputClasses };
export default ecommerceTheme;
