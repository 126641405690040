import { Button, Grid, Typography, makeStyles, Divider, TextField } from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import { funcShoppingCartPutCoupon } from "features/common/API";
import { loadShoppingCart } from "features/common/productSlice";
import { isCouponError, ShoppingCartCouponResult } from "models/ecommerceCart";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "./Banner";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import TextErrorIcon from "../../assets/icons/ui/errorIcon.svg";
import TextSuccessIcon from "../../assets/icons/ui/successIcon.svg";

const useStyles = makeStyles((theme) => ({
  couponSession: {
    height: "100%",
  },
  couponHeaderMessage: {
    fontWeight: 700,
    lineHeight: "20px",
    fontSize: "14px",
  },
  applyCouponButtonGrid: {
    paddingRight: "0px !important",
    width: "100%",
  },
  applyCouponButton: {
    ...inputClasses.mobileStylePrimary,
    whiteSpace: "nowrap",
    borderRadius: "4px",
  },
  couponInputGrid: {
    height: "48px",
    width: "100%",
  },
  couponInput: {
    
  },
  textField: {
    width: "100%",
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
  },
  textFieldSuccess: {
    border: "2px solid !important",
    borderColor: "#8FC34E !important",
  },
  textFieldLabelRoot: {
    ...inputClasses.textFieldLabel.root,
  },
  textFieldLabelFocus: {
    ...inputClasses.textFieldLabel.focus,
  },
  textFieldLabelError: {
    ...inputClasses.textFieldLabel.error,
  },
  textFieldInputRoot: {
    ...inputClasses.textFieldInput.root,
    height: "40px",
    fontSize: "12px",
  },
  textFieldInputOutlined: {
    ...inputClasses.textFieldInput.notchedOutline,
  },
  textFieldInputFocus: {
    ...inputClasses.textFieldInput.focus,
  },
  textFieldInputError: {
    ...inputClasses.textFieldInput.error,
  },
  textFieldFilled: {
    width: "100%",
  },
  disabledTextFieldLabelRoot: {
    ...inputClasses.disabledTextFieldLabel.root,
  },
  disabledTextFieldLabelFocus: {
    ...inputClasses.disabledTextFieldLabel.focus,
  },
  disabledTextFieldLabelError: {
    ...inputClasses.disabledTextFieldLabel.error,
  },
  disabledTextFieldInputRoot: {
    ...inputClasses.disabledTextFieldInput.root,
  },
  disabledTextFieldInputOutlined: {
    ...inputClasses.disabledTextFieldInput.notchedOutline,
  },
  disabledTextFieldInputFocus: {
    ...inputClasses.disabledTextFieldInput.focus,
  },
  disabledTextFieldInputError: {
    ...inputClasses.disabledTextFieldInput.error,
  },
  iconImage: {
    height: "22px",
    width: "22px",
    marginLeft: "4px",
    marginRight: "-6px",
  },
}));

const helperTextStyles = makeStyles((theme) => ({
  ...inputClasses.textFieldHelperText,
}));

export const CartCoupon = () => {
  const classes = useStyles();
  const helperTextClasses = helperTextStyles();

  const { couponCode, cartId } = useSelector((state: RootState) => state.products);
  const { conversationContext, registerAccount } = useSelector((state: RootState) => state.account);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const [ couponInput, setCouponInput ] = useState(couponCode ?? "");
  const [ isLoaderActive, setIsLoaderActive ] = useState(false);

  const [ isCouponActionMessageOpen, setIsCouponActionMessageOpen ] = useState(false);
  const [ couponActionMessage, setCouponActionMessage ] = useState("");
  const [ couponActionResult, setCouponActionResult ] = useState<ShoppingCartCouponResult>(ShoppingCartCouponResult.none);

  const dispatch: AppDispatch = useDispatch();

  const isCouponApplied: boolean = couponCode !== undefined && couponCode !== null && couponCode !== "";
  const couponButtonLabel: string = isCouponApplied ? "Eliminar" : "Aplicar";
  const couponInputState: "success" | "error" | "none" = isCouponApplied ? "success" : isCouponError(couponActionResult) ? "error" : "none";
  let couponInputEndAdorment: React.ReactNode;
  switch(couponInputState) {
    case "success":
      couponInputEndAdorment = <img className={classes.iconImage} src={TextSuccessIcon}/>;
      break;
    case "error":
      couponInputEndAdorment = <img className={classes.iconImage} src={TextErrorIcon}/>;
      break;
    default:
      couponInputEndAdorment = null;
  }

  // Update CouponMessage and Open it
  useEffect(() => {
    if (couponActionResult !== ShoppingCartCouponResult.none) {
      switch(couponActionResult) {
        case ShoppingCartCouponResult.added:
          setCouponActionMessage("Cupón aplicado exitosamente");
          break;
        case ShoppingCartCouponResult.removed:
          setCouponActionMessage("Cupón eliminado exitosamente");
          setCouponInput("");
          break;
        case ShoppingCartCouponResult.notFound:
          setCouponActionMessage("El cupón no es válido o ha expirado");
          setCouponInput("");
          break;
        case ShoppingCartCouponResult.notValid:
          setCouponActionMessage("El cupón no cumple con los términos y condiciones");
          setCouponInput("");
          break;
        default:
        case ShoppingCartCouponResult.error:
          setCouponActionMessage("El cupón no se ha aplicado");
          setCouponInput("");
      }
      setIsCouponActionMessageOpen(true);
    }
  }, [couponActionResult]);

  // After 5 seconds close CouponMessage
  useEffect(() => {
    setTimeout(() => {
      setIsCouponActionMessageOpen(false);
      setCouponActionResult(ShoppingCartCouponResult.none);
    }, 5000)
  }, [isCouponActionMessageOpen]);

  const onClick = async () => {
    setIsLoaderActive(true);
    const shoppingCart = await funcShoppingCartPutCoupon(
      conversationContext?.accountProviderId as number,
      registerAccount?.account_id as string,
      cartId,
      isCouponApplied ? "remove" : "add",
      deliveryData.encoded_route_info as string,
      couponInput,
    );
    if (shoppingCart) {
      dispatch(loadShoppingCart(shoppingCart));
      setCouponActionResult(shoppingCart.coupon_result ?? ShoppingCartCouponResult.error)
    } else {
      setCouponActionResult(ShoppingCartCouponResult.error);
    }

    setIsLoaderActive(false);
  }

  return (
    <div>
      <Grid container direction="column" spacing={3} className={classes.couponSession}>
        <Grid item>
          <Typography className={classes.couponHeaderMessage}>
            Descuentos
          </Typography>
        </Grid>
        <Grid item container spacing={1} direction="row" wrap="nowrap" className={classes.couponInputGrid} alignItems="center" justifyContent="space-between">
          <Grid item xs={9}>
            <TextField
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelRoot,
                  focused: classes.textFieldLabelFocus,
                  error: classes.textFieldLabelError,
                },
              }}
              FormHelperTextProps={{ classes: helperTextClasses }}
              InputProps={{
                classes: {
                  root: classes.textFieldInputRoot,
                  focused: classes.textFieldInputFocus,
                  notchedOutline: couponInputState === 'success' ? classes.textFieldSuccess : undefined,
                  error: classes.textFieldInputError,
                },
                endAdornment: couponInputEndAdorment,
              }}
              value={couponInput}
              error={couponInputState === "error"}
              placeholder="Escribe tu código promocional"
              variant="outlined"
              disabled={isCouponApplied}
              onChange={(e) => setCouponInput(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={3} className={classes.applyCouponButtonGrid}>
            <Button color="primary" className={classes.applyCouponButton} onClick={onClick} disabled={couponInput === ""}>
              {couponButtonLabel}
            </Button>
          </Grid>
        </Grid>
        {isCouponActionMessageOpen && (
        <Grid item>
          <Banner 
            type={couponActionResult === ShoppingCartCouponResult.added || couponActionResult === ShoppingCartCouponResult.removed ? "success" : "error"}
            message={couponActionMessage}
            showIcon={true}
          />
        </Grid>
        )}
        <Grid item>
          <Divider/>
        </Grid>
      </Grid>
      {isLoaderActive && (
        <FullPageLoader
          type={LoaderType.Authentication}
          text="Aplicando cupón..."
        />
      )}
    </div>
  );
};
