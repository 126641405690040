import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AppDispatch, RootState } from "app/store";
import { inputClasses } from "app/tccc-theme";
import trashIcon from "assets/trashIcon.svg";
import { formatNumber } from "features/common/formatHelpers";
import { handleProductView } from "features/common/gtmEventHandler";
import { removeAllProducts } from "features/common/productSlice";
import { EcommerceProduct } from "models/ecommerceProduct";
import { ReturnableTypes } from "models/returnableType";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReturnableIcon from "../../assets/icons/retornable_cart.svg";
import InfoIcon from "../../assets/icons/ui/infoIcon.svg";
import WarningIcon from "../../assets/icons/ui/warningIcon.svg";
import { ProductButtonGroup } from "./ProductButton";
const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#FBFBFB",
    maxWidth: "500px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    borderRadius: "16px",
  },
  grid: {
    textAlign: "left",
    justifyContent: "left",
    alignItems: "center",
  },
  productImageGrid: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  footerGrid: {
    width: "calc(100% - 85px)",
  },
  image: {
    height: "100px",
    [theme.breakpoints.down("md")]: {
      height: "100px",
    },
    width: "74px",
  },
  buttonsContainer: {
    flexWrap: "nowrap",
  },
  productButtonGroupGrid: {
    marginTop: theme.spacing(1),
    justifyContent: "left",
    textAlign: "left",
    display: "inherit",
  },
  productTitle: {
    textAlign: "left",
  },
  productAttributes: {
    textAlign: "left",
  },
  productPrice: {
    marginTop: theme.spacing(1),
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  originalPrice: {
    marginTop: theme.spacing(1),
    textDecoration: "line-through",
    color: "darkgrey",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  trashImage: {
    height: "30px",
  },
  trashGrid: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
  },
  badge: {
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#e02020",
    color: "white",
    position: "relative",
    maxWidth: "90px",
    borderRadius: "4px",
    padding: "3px",
    margin: "auto",
    height: "20px",
    lineHeight: "15px",
  },
  imageGrid: {
    height: "100px",
    width: "100px",
    [theme.breakpoints.down("md")]: {
      height: "135px",
      width: "150px",
    },
    margin: "0px auto",
  },
  returnableLiquid: {
    backgroundColor: "#9AECB8",
    textAlign: "center",
    justifyContent: "center",
    color: "black",
    position: "relative",
    maxWidth: "90px",
    borderRadius: "4px",
    margin: "auto",
  },
  returnableButton: {
    height: "20px",
    padding: "3px",
  },
  returnableLiquidText: {
    textAlign: "center",
    justifyContent: "center",
    color: "#202020",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  infoMessage: {
    ...inputClasses.infoMessage,
    display: "flex",
    flexWrap: "initial",
  },
  warningMessage: {
    ...inputClasses.warningMessage,
    display: "flex",
    flexWrap: "initial",
  },
  defaultMessage: {
    ...inputClasses.defaultMessage,
    display: "flex",
    flexWrap: "initial",
  },
  infoIconGrid: {
    alignItems: "center",
    display: "flex",
  },
  warningIconGrid: {
    alignItems: "center",
    display: "flex",
  },
  iconImage: {
    height: "22px",
    width: "22px",
    marginRight: "4px",
    marginLeft: "4px",
    alignItems: "center",
  },
  returnableStarterPack: {
    backgroundColor: `#FFD673`,
    textAlign: "center",
    justifyContent: "center",
    color: "white",
    position: "relative",
    maxWidth: "120px",
    borderRadius: "4px",
    padding: "3px",
    margin: "auto",
  },
  returnableStarterPackText: {
    textAlign: "center",
    justifyContent: "center",
    color: "#202020",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  mobileStylePrimary: {
    ...inputClasses.mobileStylePrimary,
  },
}));

export const CartItem = ({
  product,
  linkedProduct,
  position,
  viewPosition,
  isVisible,
}: {
  product: EcommerceProduct;
  linkedProduct: EcommerceProduct;
  position: string;
  viewPosition: number;
  isVisible: boolean;
}) => {
  const classes = useStyles();

  const { accountProvider } = useSelector((state: RootState) => state.account);
  const { cart } = useSelector((state: RootState) => state.products);

  const dispatch: AppDispatch = useDispatch();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isDeleteFormOpen, setDeleteFormOpen] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const [sendClick, setSendClick] = useState<boolean>(true);

  const removeAllClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  if (isVisible && !isViewed) {
    handleProductView(product, position, viewPosition, accountProvider?.country_id, accountProvider?.currency);
    setIsViewed(true);
  }

  const productCount = cart[product.sku].quantity;

  return (
    <Card key={product.product_id} className={classes.card} variant="outlined">
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.grid} container direction="row" spacing={1}>
          <Grid item xs={5} sm={5} md={5} className={classes.productImageGrid}>
            {product.discountPercent > 0 && (
              <Typography variant="subtitle2" className={classes.badge}>
                {`${product.discountPercent}% Menos!`}
              </Typography>
            )}

            <Grid>
              <img className={classes.image} src={product.image_url} alt={product.name} />
            </Grid>

            {product.returnable_type === ReturnableTypes.Liquid && (
              <Tooltip
                className={classes.returnableLiquid}
                title={
                  <Typography variant="body2">
                    El precio incluye solo líquido, no incluye envase. Si ya tienes envases retornables ahorra en cada
                    pedido. Para más información consulta nuestro menú de FAQ (Retornables) o nuestras políticas de
                    envío.
                  </Typography>
                }
                placement="bottom"
                arrow={true}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
              >
                <Button className={classes.returnableButton} variant="contained">
                  <Typography variant="subtitle2" className={classes.returnableLiquidText}>
                    Solo Líquido
                  </Typography>
                </Button>
              </Tooltip>
            )}
            {product.returnable_type === ReturnableTypes.StarterPack && (
              <Tooltip
                className={classes.returnableStarterPack}
                title={
                  <Typography variant="body2">
                    El precio en conjunto incluye líquido más envase. Para más información consulta nuestro menú de FAQ
                    (Retornables) o nuestras políticas de envío.
                  </Typography>
                }
                placement="bottom"
                arrow={true}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
              >
                <Button className={classes.returnableButton} variant="contained">
                  <Typography variant="subtitle2" className={classes.returnableStarterPackText}>
                    Líquido + Envase
                  </Typography>
                </Button>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={7} sm={7} md={7} className={classes.footerGrid}>
            <Typography variant="body1" className={classes.productTitle}>
              {product.shortname}
            </Typography>
            <Typography variant="subtitle1" className={classes.productAttributes}>
              {product.packaging} {product.net_content}
            </Typography>
            <Grid container spacing={1} direction="row" className={classes.buttonsContainer}>
              <Grid item className={classes.productButtonGroupGrid}>
                <ProductButtonGroup
                  product={product}
                  position={position}
                  viewPosition={viewPosition}
                ></ProductButtonGroup>
              </Grid>
              <Grid item className={classes.trashGrid}>
                <img className={classes.trashImage} alt="logo" src={trashIcon} onClick={() => removeAllClick()} />{" "}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body2" className={classes.productPrice}>
                  {accountProvider?.currency_symbol}
                  {formatNumber(
                    EcommerceProduct.totalPrice(product) * productCount,
                    accountProvider?.amount_format,
                    accountProvider?.amount_separator
                  )}
                </Typography>
              </Grid>
              <Grid item>
                {product.discountPercent > 0 && (
                  <Typography variant="body2" className={classes.originalPrice}>
                    {accountProvider?.currency_symbol}
                    {formatNumber(
                      (EcommerceProduct.totalPrice(product) + EcommerceProduct.totalDiscount(product)) * productCount,
                      accountProvider?.amount_format,
                      accountProvider?.amount_separator
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={isDeleteConfirmationOpen} style={{ textAlign: "center" }}>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body1">¿Quieres eliminar este producto de tu carrito?</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="secondary"
                onClick={() => {
                  setIsDeleteConfirmationOpen(false);
                  setDeleteFormOpen(true);
                }}
              >
                Cancelar
              </Button>
              <Button
                className={classes.mobileStylePrimary}
                color="primary"
                onClick={() => {
                  setIsDeleteConfirmationOpen(false);
                  dispatch(
                    removeAllProducts({
                      product: product,
                      position: position,
                      viewPosition: viewPosition,
                      countryId: accountProvider?.country_id,
                      currency: accountProvider?.currency,
                      sendClick: sendClick,
                    })
                  );
                  setSendClick(false);
                }}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {product.returnable_type === ReturnableTypes.Liquid && (
          <Grid item container direction="row" className={classes.infoMessage}>
            <Grid item className={classes.infoIconGrid}>
              <img className={classes.iconImage} alt="logo" src={InfoIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h6">Ten los envases vacíos para devolver</Typography>
            </Grid>
          </Grid>
        )}
        {product.returnable_type === ReturnableTypes.StarterPack && (
          <Grid item container direction="row" className={classes.defaultMessage}>
            <Grid item className={classes.infoIconGrid}>
              <img className={classes.iconImage} alt="logo" src={ReturnableIcon} />
            </Grid>
            <Grid item container direction="column" style={{ textAlign: "left", paddingLeft: "5px" }}>
              <Grid item>
                <Typography variant="body1">{linkedProduct.name}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {accountProvider?.currency_symbol}
                  {formatNumber(
                    linkedProduct.price,
                    accountProvider?.amount_format,
                    accountProvider?.amount_separator
                  )}{" "}
                  x {productCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ position: "relative" }}>
              <Typography variant="subtitle1" style={{ position: "absolute", bottom: 0, right: 0 }}>
                {`${accountProvider?.currency_symbol}${formatNumber(
                  linkedProduct.price * productCount,
                  accountProvider?.amount_format,
                  accountProvider?.amount_separator
                )}`}
              </Typography>
            </Grid>
          </Grid>
        )}
        {product.is_alcoholic === true && (
          <Grid item container direction="row" className={classes.warningMessage}>
            <Grid item className={classes.warningIconGrid}>
              <img className={classes.iconImage} alt="logo" src={WarningIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="left">
                Para la entrega de este producto, deberás presentar tu INE o identificación oficial al repartidor
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
