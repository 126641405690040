import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { funcPaymentPostVerify, funcShoppingCartPutDelivery, funcShoppingCartPutPayment } from "features/common/API";
import {
  CssBaseline,
} from "@material-ui/core";
import { FullPageLoader, LoaderType } from "features/main/FullPageLoader";
import { BotWhatsAppLink, ProductsRelativeLink } from "features/common/urlBuilder";
import { MessageDialog } from "features/main/MessageDialog";
import { clearCart } from "features/common/productSlice";

export const PaymentPage = () => {
  const { conversationContext, registerAccount, accountProvider } = useSelector((state: RootState) => state.account);
  const { billingInformation, selectedDeliveryDate } = useSelector((state: RootState) => state.deliveryData);
  const { cart, cartId } = useSelector((state: RootState) => state.products);
  const { deliveryData, selectedPaymentMethod } = useSelector((state: RootState) => state.deliveryData);

  const dispatch: AppDispatch = useDispatch();
  const [isLoaderActive, setIsLoaderActive] = useState(true);

  // Error Message State
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");

  let searchParams = new URLSearchParams(window.location.search);
  let token = searchParams.get("token") || '';

  useEffect(() => {
    (async () => {
      if (token) {
        const paymentResult = await funcPaymentPostVerify(conversationContext?.accountProviderId as number, registerAccount?.account_id as string, token);
        if (paymentResult && paymentResult.transaction_status === 'approved') {
          // Do Checkout
          window.onbeforeunload = () => true;
          const cartProducts = Object.keys(cart).map((id) => cart[id]);
          var putDeliveryPayload = {
            cart_id: cartId,
            delivery_date: selectedDeliveryDate,
            encoded_route_info: deliveryData?.encoded_route_info,
          };
    
          await funcShoppingCartPutDelivery(
            conversationContext?.accountProviderId as number,
            registerAccount?.account_id as string,
            putDeliveryPayload,
          );

          var putPayment = await funcShoppingCartPutPayment(
            conversationContext?.accountProviderId as number,
            registerAccount?.account_id as string,
            cartId,
            selectedPaymentMethod,
            deliveryData,
            billingInformation!,
            paymentResult
          );

          if (putPayment) {
            //CheckoutOrder gives OK
            const result = putPayment as EcommerceOrder;

            const CheckoutResult = {
              products: cartProducts,
              transactionId: result.supplier_order_id,
              countryId: accountProvider?.country_id,
              currency: accountProvider?.currency,
            };
            dispatch(clearCart(CheckoutResult));

            window.onbeforeunload = () => undefined;
            window.location.replace(BotWhatsAppLink(conversationContext?.whatsappNumber));
            return;
          } 
        }
      }

      //Error on Payment/Checkout processing
      window.onbeforeunload = () => undefined;

      setIsErrorMessageOpen(true);
      setErrorMessage("El pago no ha sido procesado correctamente. Intentalo nuevamente o selecciona otro método de compra");
      setErrorMessageButtonLink(ProductsRelativeLink()+"?contextId="+conversationContext?.contextId);
      setErrorMessageButtonText("Regresar al carrito");
      setIsLoaderActive(false);
    })();
  }, []);
  return (
    <>
      <CssBaseline/>
      {isLoaderActive ?<FullPageLoader type={LoaderType.Authentication} text={"Procesando pago... Por favor permanece en esta página hasta que finalice la operación."} /> : <></>}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  )
};
