import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Event } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AppDispatch, RootState } from "app/store";
import "assets/calendar.css";
import { es } from "date-fns/locale";
import { setSelectedDeliveryDate } from "features/common/deliverySlice";
import { INTERNAL_CHATBOT_DATE_FORMAT } from "features/common/formatHelpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
if (es && es.options) {
  es.options.weekStartsOn = 0;
}

const useStyles = makeStyles((theme) => ({
  deliverySection: {
    marginBottom: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#F5F5F5",
    maxWidth: "500px",
    margin: "auto",
  },
  textInfo: {
    textAlign: "left",
    fontWeight: 400,
  },
  textData: {
    textAlign: "left",
    fontWeight: 500,
  },
  paddingContent: {
    padding: "8px",
  },
}));

export const Delivery = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const { selectedAddress, selectedDeliveryDate, min_day, max_day, disabled_dates, disabled_days } =
    useSelector((state: RootState) => state.deliveryData);
  const { cart } = useSelector((state: RootState) => state.products);
  const { registerAccount } = useSelector((state: RootState) => state.account);

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    moment(selectedDeliveryDate !== "" ? selectedDeliveryDate : min_day, INTERNAL_CHATBOT_DATE_FORMAT).toDate()
  );

  let disableDates = disabled_dates.map((dDate) => moment(dDate, INTERNAL_CHATBOT_DATE_FORMAT).toDate());
  let disableDays = disabled_days.map((day) => Number(day));

  useEffect(() => {
    dispatch(setSelectedDeliveryDate(selectedDeliveryDate !== "" ? selectedDeliveryDate : min_day));
  }, [dispatch, min_day, selectedDeliveryDate]);

  const isDayAvailable = (date: any) => {
    const nDay = (date as Date).getDay();
    const dDay = disableDates.find((d) => d.getTime() === (date as Date).getTime());
    return disableDays.includes(nDay) || dDay !== undefined;
  };

  return (
    <div className={classes.deliverySection}>
      {cart && Object.keys(cart).length !== 0 && (
        <Card className={classes.root}>
          <div>
            {registerAccount?.name && (
              <Typography className={classes.textInfo}>
                <b>Enviado a:</b> {registerAccount?.name}
              </Typography>
            )}

            {selectedAddress && (
              <Typography className={classes.textInfo}>
                <b>Dirección:</b> {selectedAddress}
              </Typography>
            )}

            {registerAccount?.cell_phone_number && (
              <Typography className={classes.textInfo}>
                <b>Teléfono:</b> {registerAccount?.cell_phone_number}
              </Typography>
            )}

            <br />
            <>
              <Typography className={classes.textData}>
                Elige en el calendario que día quieres recibir tu pedido
              </Typography>
              <br />
              <div className={classes.paddingContent}>
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <DatePicker
                    value={selectedDate}
                    onChange={(date: Date | null) => {
                      setSelectedDate(date);
                      dispatch(
                        setSelectedDeliveryDate(
                          (date as Date).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        )
                      );
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Día de entrega"
                    format="dd/MM/yyyy"
                    placeholder="dd/MM/yyyy"
                    disableToolbar={true}
                    allowKeyboardControl={false}
                    InputProps={{
                      startAdornment: <Icon />,
                      endAdornment: <Event />,
                    }}
                    minDate={moment(min_day, INTERNAL_CHATBOT_DATE_FORMAT).toDate()}
                    maxDate={moment(max_day, INTERNAL_CHATBOT_DATE_FORMAT).toDate()}
                    shouldDisableDate={isDayAvailable}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </>
          </div>
        </Card>
      )}
    </div>
  );
};
