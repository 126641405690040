import { Button, Grid, makeStyles, Toolbar } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
//import SearchIcon from "@material-ui/icons/Search";

import { AppDispatch, RootState } from "app/store";
import CancelIcon from "assets/cancelIcon.svg";
import "assets/cart.css";
import CartIcon from "assets/cartIcon.svg";
import Logo from "assets/cceth_v1.svg";
import BackIcon from "assets/rightArrow.svg";
import scrollUp from "assets/scrollUpButton.svg";
import SearchIcon from "assets/searchIcon.svg";
import { setCategoryFilter, setSearchFilter } from "features/common/accountSlice";
import {
  GTM_LIST_NAME_DEFAULT,
  GTM_LIST_NAME_DIRECT_TEXT_SEARCH,
  GTM_LIST_NAME_TEXT_SEARCH,
} from "features/common/gtmEventHandler";
import { filterProducts, loadShoppingCart, setCartProductCount, setListName } from "features/common/productSlice";
import { EcommerceProduct } from "models/ecommerceProduct";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HomePageLink, ProductsRelativeLink } from "../common/urlBuilder";
import { inputClasses } from "./../../app/tccc-theme";
import { AddressList } from "./AddressList";
import { FullPageLoader, LoaderType } from "./FullPageLoader";
import { PutShoppingCartAddItems, ShoppingCart, ShoppingCartProduct } from "models/ecommerceCart";
import { funcShoppingCartAdd, funcShoppingCartGetDefault, funcShoppingCartUpdate } from "features/common/API";
import { MessageDialog } from "./MessageDialog";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "#fafafa",
    paddingTop: "16px",
  },
  toolbarItems: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: "29.01px",
    width: "199px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "280px",
    },
  },
  searchFilter: {
    backgroundColor: theme.palette.background.paper,
    display: "none",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    maxWidth: "500px",
    marginLeft: "0px",
    marginRight: "0px",
    ...inputClasses.text,
    width: "90%",
  },
  searchFilterInput: {
    outline: "none",
    border: "0px",
    width: "100%",
  },
  seachFilterInputGrid: {
    width: "calc(100% - 64px)",
  },
  searchIcon: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    paddingLeft: "5px",
    paddingRight: "5px",
    color: "#000000",
    height: "24px",
  },
  cancelIconGrid: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "32px",
    height: "33px",
  },
  cancelIconButton: {
    width: "24px",
    minWidth: "24px",
    padding: "0px",
  },
  cancelIcon: {
    padding: "0px",
    width: "24px",
    height: "16px",
  },
  myBadge: {
    backgroundColor: "#e02020",
    color: "#000000",
  },
  shoppingcartsvg: {
    [theme.breakpoints.down("md")]: {
      height: "24px",
    },
  },
  shoppingCart: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingRight: "0px",
  },
  scrollButtonsvg: {
    height: "50px",
    width: "50px",
    position: "fixed",
    top: "70%",
    right: 2,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const EcommerceNavbar = () => {
  const classes = useStyles();
  const isMobile = window.screen.width <= 768;

  const dispatch: AppDispatch = useDispatch();
  const { searchFilter, conversationContext, registerAccount } = useSelector((state: RootState) => state.account);
  let { cartProductsCount, cart, cartId } = useSelector((state: RootState) => state.products);
  const { deliveryData } = useSelector((state: RootState) => state.deliveryData);

  const [isOpen, setIsOpen] = useState(false);
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [searchFilterText, setSearchFilterText] = useState(searchFilter);
  const [goHome, setGoHome] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageButtonLink, setErrorMessageButtonLink] = useState("");
  const [errorMessageButtonText, setErrorMessageButtonText] = useState("");
  const [saveCartTimerId, setSaveCartTimerId] = useState<NodeJS.Timeout | undefined>(undefined);

  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (searchFilter !== undefined && searchFilter.length > 0) {
      dispatch(setListName({ listName: GTM_LIST_NAME_DIRECT_TEXT_SEARCH }));
      dispatch(filterProducts((product) => EcommerceProduct.filterByName(product, searchFilter)));

      if (window.location.pathname === "/products" && isMobile) setIsOpen(true);
    } else if (localStorage.isSearch === "true") {
      if (window.location.pathname === "/products" && isMobile) setIsOpen(true);
      localStorage.isSearch = false;
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      const node = inputRef.current;
      node?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (goHome) window.location.assign(HomePageLink());
  }, [goHome]);

  useEffect(() => {
    const onScroll = () => (window.scrollY > 0 ? setIsTopScroll(false) : setIsTopScroll(true));
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if(cartId) {
      if (saveCartTimerId) clearTimeout(saveCartTimerId);
      const timerId = setTimeout(saveCart, 3000);
      setSaveCartTimerId(timerId);
    }
  }, [cartProductsCount]);

  const searchToggle = () => {
    dispatch(setSearchFilter(""));
    dispatch(setCategoryFilter(""));
    if (window.location.pathname !== "/products") {
      localStorage.isSearch = true;
      navigate(ProductsRelativeLink());
    } else {
      setIsOpen(!isOpen);
    }
  };

  const saveCart = async () => {
    setIsLoaderActive(true);
    const productsToAdd: ShoppingCartProduct[] = [];
    const productsToUpdate: ShoppingCartProduct[] = [];
    Object.keys(cart).forEach((productId) => {
      const product = cart[productId];
      if(product.has_changed) {
        if (product.product_id) 
          productsToUpdate.push(product);
        else 
          productsToAdd.push(product);
      }
    });

    let shoppingCart: ShoppingCart | undefined;
    let addShoppingCart: ShoppingCart | ErrorRequestResponse | undefined;
    let updateShoppingCart: ShoppingCart | ErrorRequestResponse | undefined;
    // Add products to shopping cart
    if (productsToAdd.length !== 0) {
      var addProductsPayload: PutShoppingCartAddItems = {
        products: productsToAdd,
        cart_id: cartId,
        encoded_route_info: deliveryData.encoded_route_info,
      };
  
      addShoppingCart = await funcShoppingCartAdd(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        addProductsPayload,
      );

      if ((addShoppingCart && typeof addShoppingCart.status === "string"))
        shoppingCart = addShoppingCart as ShoppingCart;
    }
    
    if (productsToUpdate.length !== 0) {
      var updateProductsPayload: PutShoppingCartAddItems = {
        products: productsToUpdate,
        cart_id: cartId,
        encoded_route_info: deliveryData.encoded_route_info,
      };
  
      updateShoppingCart = await funcShoppingCartUpdate(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        updateProductsPayload,
      );

      if ((updateShoppingCart && typeof updateShoppingCart.status === "string"))
        shoppingCart = updateShoppingCart as ShoppingCart;
    };

    if ((addShoppingCart && typeof addShoppingCart.status !== "string") || (updateShoppingCart && typeof updateShoppingCart.status !== "string")) {
      const shoppingCartAddError = (updateShoppingCart && updateShoppingCart.status !== "string")
        ? updateShoppingCart as ErrorRequestResponse
        : addShoppingCart as ErrorRequestResponse;

      var errorMessage: string = shoppingCartAddError.data.error.toString();
      if (shoppingCartAddError && shoppingCartAddError.status === 400 && errorMessage.includes("Error ocurred adding the product with name") === true ) {
        var productName = errorMessage.slice(errorMessage.indexOf("name ") + 5);
        if (productName) {
          setIsErrorMessageOpen(true);
          setErrorMessage(`Lo sentimos 😔, el producto ${productName} no se ha agregado correctamente.`);
          setErrorMessageButtonLink("");
          setErrorMessageButtonText("Ok");
        }
      } else {
        // Unexpected error on Checkout call
        setIsErrorMessageOpen(true);
        setErrorMessage(`Lo sentimos 😔, no se han cargado correctamente los productos.`);
        setErrorMessageButtonLink("");
        setErrorMessageButtonText("Ok");
      }

      // Get cart to clear all products from the cart
      shoppingCart = await funcShoppingCartGetDefault(
        conversationContext?.accountProviderId as number,
        registerAccount?.account_id as string,
        cartId
      );
    };

    if(shoppingCart)
      dispatch(loadShoppingCart(shoppingCart));
    setIsLoaderActive(false);
  };

  const goCart = () => {
    if (window.location.pathname !== "/products") {
      dispatch(setSearchFilter(""));
      navigate(ProductsRelativeLink());
    }
    const cartContainer = document.getElementById("cart-container");
    const offset = 200;
    const containerPosition = cartContainer?.getBoundingClientRect().top;
    var offsetPosition = containerPosition! + window.scrollY - offset;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const logoToggle = () => {
    if (window.location.pathname !== "/" && window.location.pathname !== "/geofencing") {
      dispatch(setSearchFilter(""));
      dispatch(setCategoryFilter(""));
      setGoHome(true);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const cancelOnClick = () => {
    setIsOpen(false);
  };

  const clearSearchFilter = () => {
    setSearchFilterText("");
    dispatch(setSearchFilter(""));
    dispatch(setListName({ listName: GTM_LIST_NAME_DEFAULT }));
    dispatch(filterProducts((product) => true));
  };

  const onInputClick = () => {
    if (window.location.pathname !== "/products") {
      dispatch(setSearchFilter(""));
      dispatch(setCategoryFilter(""));
      navigate(ProductsRelativeLink());
    }
  };

  const goProducts = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to handle when the search products text input is changed.
  function onChangeSearcher(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    dispatch(setSearchFilter(""));
    setSearchFilterText(e.target.value);
    dispatch(setListName({ listName: GTM_LIST_NAME_TEXT_SEARCH }));
    dispatch(filterProducts((product) => EcommerceProduct.filterByName(product, e.target.value)));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // Fix to avoid cases when the cartProductCount was not created yet and is null.
  if (!cartProductsCount) {
    dispatch(setCartProductCount());
    cartProductsCount = 0;
    const productIds: string[] = Object.keys(cart);
    productIds.forEach((productId) => (cartProductsCount += cart[productId].quantity));
  }

  return (
    <>
      <AddressList />
      <Toolbar className={classes.toolbar}>
        <div
          id={"LogoIconButton"}
          className={classes.toolbarItems}
          style={{ display: isOpen && isMobile ? "none" : "flex" }}
        >
          <img className={classes.logo} onClick={logoToggle} alt="logo" src={Logo} />
        </div>
        {isOpen && (
          <Grid
            className={classes.searchFilter}
            container
            spacing={1}
            direction="row"
            style={{
              display: isOpen ? "flex" : "",
              width: isOpen ? "100%" : "",
            }}
          >
            <Grid item className={classes.searchIcon}>
              <Button className={classes.cancelIconButton} onClick={cancelOnClick}>
                <img src={BackIcon} className={classes.cancelIcon} />
              </Button>
            </Grid>
            <Grid item className={classes.seachFilterInputGrid}>
              <input
                /*inputRef={inputRef}*/
                className={classes.searchFilterInput}
                placeholder="Buscar productos..."
                onChange={onChangeSearcher}
                tabIndex={0}
                onClick={onInputClick}
                ref={inputRef}
                value={searchFilterText}
                onBlur={() => setTimeout(cancelOnClick, 1)}
              />
            </Grid>
            <Grid
              item
              className={classes.cancelIconGrid}
              style={{ display: searchFilterText.length > 0 ? "block" : "none" }}
            >
              <Button className={classes.cancelIconButton} onClick={clearSearchFilter}>
                <img src={CancelIcon} className={classes.cancelIcon} />
              </Button>
            </Grid>
          </Grid>
        )}

        {!isOpen && (
          <div
            id={"SearchIconButton"}
            onClick={searchToggle}
            className={classes.searchIcon}
            style={{
              position: isOpen ? "absolute" : undefined,
              //display: isOpen ? "none" : undefined,
              //width: isOpen ? "100%" : "",
              right: isOpen ? "23px" : "",
              color: "#000000",
            }}
          >
            <img src={SearchIcon} />
          </div>
        )}

        <div id={"ShoppingCartIconButton"} onClick={goCart} className={classes.shoppingCart}>
          <Badge classes={{ badge: classes.myBadge }} badgeContent={cartProductsCount}>
            <img className={classes.shoppingcartsvg} alt="logo" src={CartIcon} />{" "}
          </Badge>
        </div>
        <div
          style={{
            display: isTopScroll ? "none" : "block",
          }}
          onClick={goProducts}
        >
          <img id="scrollUpButton" className={classes.scrollButtonsvg} alt="logo" src={scrollUp} />{" "}
        </div>
      </Toolbar>
      {isLoaderActive ? (
        <FullPageLoader
          type={LoaderType.Authentication}
          text="Cargando productos..."
        />
      ) : (
        <></>
      )}
      {isErrorMessageOpen ? (
        <MessageDialog
          message={errorMessage}
          link={errorMessageButtonLink}
          text={errorMessageButtonText}
          setIsDialogActive={setIsErrorMessageOpen}
        />
      ) : null}
    </>
  );
};

export const Navbar = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarItems} style={{ display: "flex" }}>
        <img className={classes.logo} alt="logo" src={Logo} />
      </div>
    </Toolbar>
  );
};
