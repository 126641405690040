import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { PaginationItem } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import { RootState } from "app/store";
import { GeofencingPageLink } from "features/common/urlBuilder";
import { EcommerceProduct } from "models/ecommerceProduct";
import React, { useEffect, useState } from "react";
import TrackVisibility from "react-on-screen";
import { useSelector } from "react-redux";

import { inputClasses } from "app/tccc-theme";
import WarnIcon from "../../assets/icons/ui/warningIcon.svg";
import { MessageDialog } from "./MessageDialog";
import { Product } from "./Product";

import leftArrow from "assets/left_arrow.svg";
import rightArrow from "assets/right_arrow.svg";
import downArrow from "assets/downArrow.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationSection: {
      textAlign: "center",
    },
    paginationSectionLabel: {
      fontFamily: "TCCC-UnityHeadline",
      fontWeight: 400,
      color: "#000000",
      fontSize: "14px",
      lineHeight: "16.8px",
      "&.MuiInputLabel-shrink": {
        transform: "translate(40px, 10px) scale(0.8)",
      },
    },
    paginationSectionSelect: {
      fontFamily: "TCCC-UnityHeadline",
      fontWeight: 400,
      backgroundColor: "#F5F5F5",
      "& .MuiSelect-root": {
        paddingRight: "0px",
      },  
    },
    formControl: {
      marginTop: "8px",
      marginBottom: "8px",
      minWidth: 200,
    },
    paginator: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#F5F5F5",
      },  
    },
    showedProduct: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    productCatalogueGrid: {
      maxWidth: "500px",
    },
    paginationGrid: {
      maxWidth: "492px",
    },
    noProductsTypography: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      textAlign: "center",
    },
    warningMessage: {
      ...inputClasses.warningMessage,
      display: "flex",
      flexWrap: "initial",
    },
    warnIconGrid: {
      alignItems: "center",
      display: "flex",
    },
    iconImage: {
      height: "24px",
      width: "24px",
      marginRight: "4px",
      marginLeft: "4px",
      alignItems: "center",
    },
    visibility: {
      height: "100%",
    },
    previousButton: {
      content: `url(${leftArrow})`,
      padding: "7px",
    },
    nextButton: {
      content: `url(${rightArrow})`,
      padding: "7px",
    },
  })
);

export const ProductList = () => {
  const classes = useStyles();
  const pageSizes = [8, 16, 24];

  const { products, selectedProducts } = useSelector((state: RootState) => state.products);
  const { categories } = useSelector((state: RootState) => state.categories);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(pageSize);
  const [showedProducts, setShowedProducts] = useState<EcommerceProduct[]>(
    selectedProducts?.slice(startIndex, endIndex)
  );

  const [isSessionOver, setIsSessionOver] = useState(false);

  useEffect(() => {
    //let selectedProducts = products.filter(
    //  (product) => selectedSubcategories.indexOf(product.extension_attributes.category_id[0]) !== -1
    //);
    if (products.length === 0 || categories.length === 0) {
      setIsSessionOver(true);
      return;
    }
    setShowedProducts(selectedProducts?.slice(0, pageSize));
    setPage(1);
  }, [products, selectedProducts]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const stop = value * pageSize;
    setShowedProducts(selectedProducts.slice(stop - pageSize, stop));
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setShowedProducts(selectedProducts.slice(0, event.target.value as number));
    setPageSize(event.target.value as number);
    setPage(1);
    setStartIndex(0);
    setEndIndex(pageSize);
  };

  const CustomIcon = () => (
    <img src={downArrow} alt="Down Arrow" style={{ padding: "6px" }}/>
  );

  return (
    <>
      <Grid className={classes.productCatalogueGrid} container direction="row" spacing={1}>
        {showedProducts &&
          showedProducts.map((product, index) => (
            <Grid className={classes.showedProduct} item xs={6} sm={6} md={6}>
              <TrackVisibility once className={classes.visibility}>
                {({ isVisible }) => (
                  <Product
                    key={product.product_id}
                    product={product}
                    viewPosition={pageSize * (page - 1) + index + 1}
                    isVisible={isVisible}
                  />
                )}
              </TrackVisibility>
            </Grid>
          ))}
      </Grid>
      {(!showedProducts || showedProducts.length === 0) && (
        <Grid item container direction="row" className={classes.warningMessage}>
          <Grid item className={classes.warnIconGrid}>
            <img className={classes.iconImage} alt="logo" src={WarnIcon} />
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: "14px" }} variant="h6">
              No hay productos que mostrar
            </Typography>
          </Grid>
        </Grid>
      )}
      {selectedProducts?.length > 0 && (
        <Grid className={classes.paginationGrid} item xs={12} sm={12} md={12}>
          <div className={classes.paginationSection}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="demo-simple-select-filled-label" className={classes.paginationSectionLabel}>Items por página</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={handlePageSizeChange}
                value={pageSize}
                IconComponent={CustomIcon}
                disabled={selectedProducts?.length === 0}
                className={classes.paginationSectionSelect}
              >
                {pageSizes.map((size) => (
                  <MenuItem id={`product-pagination-value-${size}`} key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Pagination
              count={Math.ceil(selectedProducts?.length / pageSize)}
              className={classes.paginator}
              siblingCount={1}
              page={page}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              renderItem={(item) => {
                switch (item.type) {
                  case "next":
                    return (
                      <PaginationItem id={`product-page-value-${item.page}`} {...item} className={classes.nextButton} />
                    );
                  case "previous":
                    return (
                      <PaginationItem
                        id={`product-page-value-${item.page}`}
                        {...item}
                        className={classes.previousButton}
                      />
                    );
                  default:
                    return <PaginationItem id={`product-page-value-${item.page}`} {...item} />;
                }
              }}
            />
          </div>
        </Grid>
      )}
      {isSessionOver ? (
        <MessageDialog
          message={"Lo siento 😅, tu sesión ha expirado, presiona regresar para continuar con el flujo de compra."}
          link={GeofencingPageLink()}
          text="Regresar"
          setIsDialogActive={setIsSessionOver}
        />
      ) : null}
    </>
  );
};
