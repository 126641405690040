import { EcommerceProduct } from 'models/ecommerceProduct';
import TagManager from 'react-gtm-module';
import { ProductsState } from './productSlice';
// ProductsSlice Handlers

export const handleProductsView = (
  ecommerceProducts: EcommerceProduct[],
  position: string,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: "addImpression",
      ecommerce: {
        currencyCode: currency,
        impressions: ecommerceProducts.map((ecommerceProduct, index) => {
          return {
            id: ecommerceProduct.product_id,
            name: ecommerceProduct.name,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            brand: 'brand_undefined',
            category: ecommerceProduct.category_name,
            variant: 'variant_undefined',
            dimension1: ecommerceProduct.packaging,
            dimension2: ecommerceProduct.net_content,
            position: index + 1,
            list: position
          }
        })
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
}

export const handleProductView = (
  ecommerceProduct: EcommerceProduct,
  position: string,
  viewPosition: number,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: "addImpression",
      ecommerce: {
        currencyCode: currency,
        impressions: [
          {
            id: ecommerceProduct.product_id,
            name: ecommerceProduct.name,
            price: EcommerceProduct.totalPrice(ecommerceProduct),
            brand: 'brand_undefined',
            category: ecommerceProduct.category_name,
            variant: 'variant_undefined',
            dimension1: ecommerceProduct.packaging,
            dimension2: ecommerceProduct.net_content,
            position: viewPosition,
            list: position
          }
        ]
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
}

export const handleProductClick = (
  ecommerceProduct: EcommerceProduct,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'productClick',
      ecommerce: {
        currencyCode: currency,
        click: {
          actionField: {
            list: position,
          },
          products: [
            {
              id: ecommerceProduct.product_id,
              name: ecommerceProduct.name,
              price: EcommerceProduct.totalPrice(ecommerceProduct),
              brand: 'brand_undefined',
              category: ecommerceProduct.category_name,
              variant: 'variant_undefined',
              dimension1: ecommerceProduct.packaging,
              dimension2: ecommerceProduct.net_content,
              position: viewPosition
            },
          ],
        },
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
}

export const handleAddToCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);
  var quantity: number =
    ProductsState.cart[ecommerceProduct.sku] === undefined
      ? 0
      : ProductsState.cart[ecommerceProduct.sku].quantity;

  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: currency,
        add: {
          actionField: {
            list: position,
          },
          products: [
            {
              id: ecommerceProduct.product_id,
              name: ecommerceProduct.name,
              price: EcommerceProduct.totalPrice(ecommerceProduct),
              brand: 'brand_undefined',
              category: ecommerceProduct.category_name,
              variant: 'variant_undefined',
              dimension1: ecommerceProduct.packaging,
              dimension2: ecommerceProduct.net_content,
              quantity: quantity,
              position: viewPosition
            },
          ],
        },
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
};

export const handleRemoveFromCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string,
) => {
  var gtm = fillGTMInfo(countryId);

  var quantity: number =
    ProductsState.cart[ecommerceProduct.sku] === undefined
      ? 0
      : ProductsState.cart[ecommerceProduct.sku].quantity;
  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: currency,
        remove: {
          actionField: {
            list: position,
          },
          products: [
            {
              id: ecommerceProduct.product_id,
              name: ecommerceProduct.name,
              price: EcommerceProduct.totalPrice(ecommerceProduct),
              brand: 'brand_undefined',
              category: ecommerceProduct.category_name,
              variant: 'variant_undefined',
              dimension1: ecommerceProduct.packaging,
              dimension2: ecommerceProduct.net_content,
              quantity: quantity,
              position: viewPosition
            },
          ],
        },
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
};

export const handleRemoveAllFromCartButtonClick = (
  ecommerceProduct: EcommerceProduct,
  ProductsState: ProductsState,
  position: string,
  viewPosition?: number,
  countryId?: string,
  currency?: string
) => {
  var gtm = fillGTMInfo(countryId);
  var quantity: number = 0;
  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: currency,
        remove: {
          actionField: {
            list: position,
          },
          products: [
            {
              id: ecommerceProduct.product_id,
              name: ecommerceProduct.name,
              price: EcommerceProduct.totalPrice(ecommerceProduct),
              brand: 'brand_undefined',
              category: ecommerceProduct.category_name,
              variant: 'variant_undefined',
              dimension1: ecommerceProduct.packaging,
              dimension2: ecommerceProduct.net_content,
              quantity: quantity,
              position: viewPosition
            },
          ],
        },
      },
    },
    auth: gtm.auth,
    preview: gtm.auth,
  };
  TagManager.initialize(tagManagerArgs);
};

export const handleCheckoutButtonClick = (
  ecommerceProducts: EcommerceProduct[],
  transactionId: string,
  ProductsState: ProductsState,
  countryId?: string
) => {
  var gtm = fillGTMInfo(countryId);
  var totalRevenue = 0;
  ecommerceProducts.forEach((product) => {
    var quantity: number =
      ProductsState.cart[product.sku] === undefined ? 0 : ProductsState.cart[product.sku].quantity;
    totalRevenue += EcommerceProduct.totalPrice(product) * quantity;
  });

  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            affiliation: 'WebviewChatbotFramework',
            revenue: totalRevenue,
            tax: 0,
            shipping: 0,
          },
          products: ecommerceProducts.map((product) => {
            var quantity: number =
              ProductsState.cart[product.sku] === undefined
                ? 0
                : ProductsState.cart[product.sku].quantity;

            return {
              id: product.product_id,
              name: product.name,
              price: EcommerceProduct.totalPrice(product),
              brand: 'brand_undefined',
              category: product.category_name,
              variant: 'variant_undefined',
              dimension1: product.packaging,
              dimension2: product.net_content,
              quantity: quantity,
              position: product.view_position,
            };
          }),
        },
      },
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
};

// CategoriesSlice handlers
export const handleMainCategoryButtonClick = (parentCategoryName: string | undefined, countryId?: string) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'Filter Product Menu',
      hierarchy1: parentCategoryName,
    },
    auth: gtm.auth,
    preview: gtm.auth,
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const handleChildCategoryButtonClick = (
  parentCategoryName: string | undefined,
  childCategoryName: string | undefined,
  countryId?: string
) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'Filter Product Menu',
      hierarchy1: parentCategoryName,
      hierarchy2: childCategoryName,
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.initialize(tagManagerArgs);
};

// Geofencing gtm event handlers
export const handleWebviewInizialized = (contextId: string, bottler: string, countryId?: string) => {
  var gtm = fillGTMInfo(countryId);
  const tagManagerArgs = {
    gtmId: gtm.id,
    dataLayer: {
      event: 'bottlerAssigned',
      bottler: bottler,
    },
    auth: gtm.auth,
    preview: gtm.preview,
  };
  TagManager.dataLayer(tagManagerArgs);
};

function fillGTMInfo(countryId?: string): GTMProps {
  var gtmCode = process.env['REACT_APP_GTM_ID_' + countryId] || '';
  var gtmAuth = process.env['REACT_APP_GTM_AUTH_' + countryId] || '';
  var gtmPreview = process.env['REACT_APP_GTM_PREVIEW_' + countryId] || '';
  return {
    id: gtmCode,
    auth: gtmAuth,
    preview: gtmPreview,
  };
}

export const GTM_LIST_NAME_DEFAULT = "Catálogo de Productos";

export const GTM_LIST_NAME_DIRECT_CATEGORY = "Categoría Directa";
export const GTM_LIST_NAME_DIRECT_PROMOTION = "Promoción Directa";
export const GTM_LIST_NAME_DIRECT_TEXT_SEARCH = "Búsqueda por texto Directa";

export const GTM_LIST_NAME_PARENT_CATEGORY = "Categoría Padre";
export const GTM_LIST_NAME_CHILD_CATEGORY = "Categoría Hija";
export const GTM_LIST_NAME_TEXT_SEARCH = "Búsqueda por texto";

export const GTM_LIST_NAME_CAROUSEL = "Carousel";
export const GTM_LIST_NAME_SHOPPING_CART = "Carrito";

export const GTM_LIST_NAME_EDIT_RE_ORDER = "Editar ReOrder";
export const GTM_LIST_NAME_EDIT_SUGGESTED_ORDER = "Editar Pedido Sugerido";
